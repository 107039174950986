<template>
	<div>
		<div class="bg">
			<img src="@/assets/image/bg.png" class="bg-img">
			<div class="bg-m">
			</div>
			<div class="bg-content">
				<Navigation></Navigation>
				<div class="content-main">
					<div class="main-money">
						王者兼职
					</div>
					<div class="main-souce">
						一站式灵活用工服务平台
					</div>
					<div class="main-button">
						<div class="main-button-item" @click="recruit">
							我是招聘者
						</div>
						<div class="main-button-item2" @click="wanted">
							我是求职者
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="banner">
			<div class="banner-title">
				深耕兼职领域
			</div>
			<div class="banner-note">
				只为带来更好的体验
			</div>
			<div class="banner-main">
				<!-- <div class="banner-main-item">
					<img src="../assets/image/banner1.png">
					<span class="banner-main-item-title">海量岗位</span>
					<span class="banner-main-item-content" style="margin-bottom: .08rem;">营销推广、餐饮服务、志愿服务…</span>
					<span class="banner-main-item-content">你想要的都在这里</span>
				</div>
				<div class="banner-main-item">
					<img src="../assets/image/banner2.png">
					<span class="banner-main-item-title">多重审核</span>
					<span class="banner-main-item-content" style="margin-bottom: .08rem;">商家、职位多重审核，保证发布职位</span>
					<span class="banner-main-item-content">安全合规、真实有效</span>
				</div>
				<div class="banner-main-item">
					<img src="../assets/image/banner3.png">
					<span class="banner-main-item-title">薪资保障</span>
					<span class="banner-main-item-content">平台担保薪资权益</span>
				</div> -->
				<div class="shengengSwiper">
					<img src="../assets/image/left.png" class="shengengSwiper-button swiper-button-prev">
					<swiper ref="shengengSwiper" :options="swiperOptions2" @change="shengengChange"
						style="width:4.48rem;height: 6.92rem;margin:0 auto">
						<swiper-slide>
							<img src="../assets/image/shengeng1.png" class="sw-img2">
						</swiper-slide>
						<swiper-slide>
							<img src="../assets/image/shengeng2.png" class="sw-img2">
						</swiper-slide>
						<swiper-slide>
							<img src="../assets/image/shengeng3.png" class="sw-img2">
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
					<img src="../assets/image/right.png" class="shengengSwiper-button swiper-button-next">
				</div>
				<div class="detail-desc">
					<div class="detail-desc-item" :class="index2==1 || index2==2?'detail-desc-item2 blue':''" @mouseenter="descMouseEnter(2)" @mouseleave="descMouseLeave">
						<img src="../assets/image/banner1.png">
						<div class="detail-desc-right">
							<p>海量岗位</p>
							<dd>营销推广、餐饮服务、志愿服务…你想要的都在这里</dd>
						</div>
					</div>
					<div class="detail-desc-item" :class="index2==3?'detail-desc-item2 green':''" @mouseenter="descMouseEnter(3)" @mouseleave="descMouseLeave">
						<img src="../assets/image/banner2.png">
						<div class="detail-desc-right">
							<p>多重审核</p>
							<dd>商家、职位多重审核，保证发布职位。安全合规、真实有效</dd>
						</div>
					</div>
					<div class="detail-desc-item" :class="index2==4?'detail-desc-item2 orange':''" @mouseenter="descMouseEnter(4)" @mouseleave="descMouseLeave">
						<img src="../assets/image/banner3.png">
						<div class="detail-desc-right">
							<p>薪资保障</p>
							<dd>平台担保薪资权益</dd>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="hot">
			<div class="hot-title">
				<img src="../assets/image/hot-t.png">
			</div>
			<div class="hot-content">
				<div class="hot-content-item" v-for="(item,index) in carouselPostLists.slice(swiperNum,swiperNum+3)"
					:key="index" @click="homePost(item.postId)">
					<div class="item-title">
						<span class="item-title-name">{{item.postTitle}}</span>
						<span class="item-title-price">{{price(item.salaryType)}}</span>
					</div>
					<div class="item-tag">
						<div class="item-tag-item">
							<img src="../assets/image/tag1.png">
							<span>{{item.eduRequire}}</span>
						</div>
						<div class="item-tag-item2">
							<img src="../assets/image/tag2.png">
							<span>{{item.expRequire}}</span>
						</div>
					</div>
					<div class="item-time">
						<span>发布时间：</span>
						{{item.pubTime}}
					</div>
					<div class="line">
					</div>
					<div class="item-company">
						<img :src="item.pubUser.headImg">
						<div class="item-company-main">
							<div class="item-company-main-top">
								<span class="item-company-main-top-name">{{item.pubUser.nickName}}</span>
								<div class="dian"></div>
								<span class="item-company-main-top-hr">{{item.pubUser.position}}</span>
							</div>
							<div class="item-company-main--bottom">
								<span>{{item.pubUser.companyName}}</span>
								<!-- <span>{{item.pubUser.companyScale}}</span> -->
							</div>
						</div>
						<div class="item-company-di">
							<img src="../assets/image/dizhi.png" class="item-company-di-img">
							<span>{{pcaaProvince(item.province)}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="hot-more" @click="goInf">
				查看更多
			</div>
		</div>
		<div class="active">
			<div class="active-title">
				特别活动专区
			</div>
			<div class="active-note">
				与 420000+商户共创灵活用工生态
			</div>
			<div class="active-content">
				<div class="active-swiper">
					<swiper ref="mySwipers" :options="swiperOptions" style="width:5.73rem;height: 4rem;margin:0 auto">
						<swiper-slide>
							<img src="../assets/image/swiper1.png" class="sw-img">
						</swiper-slide>
						<swiper-slide>
							<img src="../assets/image/swiper2.png" class="sw-img">
						</swiper-slide>
						<swiper-slide>
							<img src="../assets/image/swiper3.png" class="sw-img">
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>
				<div class="active-main">
					<swiper ref="mySwiper" :options="swiperOption" style="width:5.73rem;height: 4rem;margin:0 auto">
						<swiper-slide>
							<div class="active-main-item-te" @click="goreturn(0)">
								<img src="../assets/image/te1.png">
								<div class="item-te-main">
									<span class="item-te-main-title">名企业专区</span>
									<span class="item-te-main-title2">多重算法推荐优质企业，助力求职者轻松拿offer</span>
								</div>
							</div>
							<div class="active-main-item" @click="goreturn(1)">
								<img src="../assets/image/a2.png">
								<span>志愿者</span>
							</div>
							<div class="active-main-item" @click="goreturn(2)">
								<img src="../assets/image/a3.png">
								<span>体验测评</span>
							</div>

						</swiper-slide>
						<swiper-slide>

							<div class="active-main-item" @click="goreturn(0)">
								<img src="../assets/image/a1.png">
								<span>名企业专区</span>
							</div>
							<div class="active-main-item-te" @click="goreturn(1)">
								<img src="../assets/image/te2.png">
								<div class="item-te-main">
									<span class="item-te-main-title">志愿者</span>
									<span class="item-te-main-title2">为相关单位招募社会爱心人士、大学生参与志愿者社会实践服务活动</span>
								</div>
							</div>
							<div class="active-main-item" @click="goreturn(2)">
								<img src="../assets/image/a3.png">
								<span>体验测评</span>
							</div>
						</swiper-slide>
						<swiper-slide>

							<div class="active-main-item" @click="goreturn(0)">
								<img src="../assets/image/a1.png">
								<span>名企业专区</span>
							</div>
							<div class="active-main-item" @click="goreturn(1)">
								<img src="../assets/image/a2.png">
								<span>志愿者</span>
							</div>
							<div class="active-main-item-te" @click="goreturn(2)">
								<img src="../assets/image/te3.png">
								<div class="item-te-main">
									<span class="item-te-main-title">体验测评</span>
									<span class="item-te-main-title2">帮助数千家企业招募评测人员，通过市场反馈，进一步了解市场和用
										户的需求，改进产品的设计</span>
								</div>
							</div>
						</swiper-slide>
					</swiper>

				</div>
			</div>
		</div>
		<div class="part">
			<div class="part-title">
				<img src="../assets/image/he1.png">
			</div>
			<div class="part-main">
				<img src="../assets/image/he2.png">
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>
<script>
	import $http from '../common/api/axios.js'
	import {
		carouselPosts,
	} from '../common/api/api.js'
	import {
		pcaa
	} from 'area-data';
	import Navigation from '../components/common/Navigation.vue'
	import Footer from '../components/common/Footer.vue'
	let that
	export default {
		components: {
			Navigation,
			Footer
		},
		data() {
			return {
				swiperNum: 0,
				index: '',
				swiperOption: {
					direction: 'vertical',
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
					on: {
						slideChange() {
							that.index = this.activeIndex + 1
							that.$refs.mySwipers.$swiper.slideTo(this.activeIndex, 0, true);
						},
					},
				},
				swiperOptions: {
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					pagination: {
						el: '.swiper-pagination'
					},
					on: {
						slideChange() {

							that.index = this.activeIndex + 1
							that.$refs.mySwiper.$swiper.slideTo(this.activeIndex, 0, true);
						},
					},
				},
				swiperOptions2: {
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					loop: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					on: {
						slideChange: function() {
							// console.log('改变了，activeIndex为' + this.activeIndex);
							that.index2 = this.activeIndex
							console.log('index2',that.index2)
						},
					},
				},
				index2: '',
				industryInfos: [],
				currentIndex: '',
				homePostLists: [],
				activeName: '',
				carouselPostLists: [],

			}
		},
		beforeCreate() {
			this.$store.commit('getdid')
		},
		created() {
			this.swiperNum = Math.ceil(Math.random() * 6);
			that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: carouselPosts,
				}).then((res) => {
					this.carouselPostLists = res.data.carouselPostLists
				}).catch(() => {

				});

			})
		},
		methods: {
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]
			},
			goInf() {
				this.$router.push({
					path: '/pj-information',
					query: {
						value: 1,
						currcet: 0
					}
				})
			},
			goreturn(index) {
				that.$refs.mySwiper.$swiper.slideTo(index, 0, true);
			},
			wanted() {
				let type = localStorage.getItem("type");
				if (type == 0) {
					this.$router.push('./pj-wanted')
				} else {
					this.$message({
						message: '请登录个人账号',
						type: 'warning',
						duration: '2000'
					});
					// this.$router.push('./pj-login')
				}

			},
			recruit() {
				let type = localStorage.getItem("type");
				if (type == 1) {
					this.$router.push('./pj-recruit')
				} else {
					this.$message({
						message: '请登录机构账号',
						type: 'warning',
						duration: '2000'
					});
					// this.$router.push('./pj-login')
				}
			},
			homePost(postId) {
				let id = postId
				this.$router.push({
					path: '/pj-details',
					query: {
						id: id
					}
				})
			},
			shengengChange(e) {
				console.log(e)
			},
			descMouseEnter(index) {
				this.$refs.shengengSwiper.$swiper.autoplay.stop();
				this.$refs.shengengSwiper.$swiper.slideTo(index,600,false)
			},
			descMouseLeave() {
				this.$refs.shengengSwiper.$swiper.autoplay.start();
			},
		}
	}
</script>
<style scoped>
	.part-main img {
		width: 11.82rem;
	}

	.part-title img {
		width: 1.6rem;
	}

	.part-title {
		margin-bottom: 1rem;
	}

	.part {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 19.2rem;
		height: 8.04rem;
	}

	.active-main-item span {
		margin-left: .43rem;
		font-size: .18rem;
		font-weight: 400;
		color: #333333;
	}

	.active-main-item>img {
		width: .47rem;
	}

	.active-main-item {
		display: flex;
		align-items: center;
		padding-left: .25rem;
		margin-bottom: .86rem;
	}

	.item-te-main-title2 {
		font-size: .12rem;
		font-weight: 400;
		color: #666666;
	}

	.item-te-main-title {
		margin-bottom: .16rem;
		font-size: .28rem;
		font-weight: 600;
		color: #333333;
	}

	.item-te-main {
		display: flex;
		flex-direction: column;
		margin-left: .14rem;
	}

	.active-main-item-te {
		display: flex;
		align-items: center;
		margin-bottom: .86rem;
	}

	.active-main-item-te img {
		width: .92rem;
	}

	.active-main {
		margin-left: 1.36rem;
	}

	.sw-img {
		width: 5.73rem;
		height: 3.94rem;
	}

	.active-note {
		margin-bottom: .73rem;
		font-size: .20rem;
		font-weight: 400;
		color: #999999;
	}

	.active-title {
		margin-bottom: .08rem;
		font-size: .40rem;
		font-weight: bold;
		color: #3C3C3C;

	}

	.active-content {
		display: flex;
	}

	.active {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: .83rem;
		box-sizing: border-box;
		width: 19.2rem;
		height: 7.12rem;
		background: #F2F4F7;
		font-family: PingFang SC;
	}

	.hot-more {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.53rem;
		height: .33rem;
		background: #F5F5F5;
		border-radius: 2px;
		font-size: .14rem;
		font-weight: 600;
		color: #333333;
		font-family: PingFang SC;
		cursor: pointer;
	}

	.item-company-di span {}

	.item-company-di-img {
		margin-right: .04rem;
		width: .10rem;
	}

	.item-company-di {
		width: 60px;
		text-align: right;
	}

	.item-company-main--bottom span {
		margin-bottom: .05rem;
	}

	.item-company-main--bottom {
		display: flex;
		flex-direction: column;
		width: 2.1rem;
		font-size: .12rem;
		font-weight: 400;
		color: #666666;
	}

	.item-company-main-top-hr {
		font-size: .14rem;
		font-weight: 600;
		color: #666666;
	}

	.item-company-main-top-name {
		font-size: .14rem;
		font-weight: 600;
		color: #333333;
	}

	.item-company-main-top {
		display: flex;
		align-items: center;
		margin-bottom: .08rem;
	}

	.dian {
		margin: 0 .04rem;
		width: .04rem;
		height: .04rem;
		background: #666666;
		border-radius: 50%;
	}

	.item-company>img {
		margin-top: -.05rem;
		margin-right: .1rem;
		width: .45rem;
		border-radius: .22rem;
	}

	.item-company {
		box-sizing: border-box;
		padding-top: .25rem;
		display: flex;
		align-items: center;
	}

	.line {
		width: 3.5rem;
		height: 1px;
		background: #D1D1D1;
	}

	.item-time {
		margin-bottom: .13rem;
		padding-top: .28rem;
		font-size: .12rem;
		font-weight: 400;
		color: #666666;
	}

	.item-time span {
		color: #999999;
	}

	.item-tag-item2 span {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		transform: scale(0.84, 0.84);
	}

	.item-tag-item2 img {
		margin-right: .05rem;
		width: .16rem;
	}

	.item-tag-item2 {
		padding-left: .10rem;
		padding-right: .10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: .64rem;
		height: .20rem;
		background: #54EDAC;
	}

	.item-tag-item span {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		transform: scale(0.84, 0.84);
	}

	.item-tag-item img {
		margin-right: .05rem;
		width: .16rem;
	}

	.item-tag-item {
		padding-left: .10rem;
		padding-right: .10rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: .1rem;
		min-width: .64rem;
		height: .20rem;
		background: #5483ED;
	}

	.item-tag {
		display: flex;
	}

	.item-title-price {
		font-size: .22rem;
		font-weight: 600;
		color: #EA1C1C;
	}

	.item-title-name {
		font-size: .20rem;
		font-weight: bold;
		color: #333333;
	}

	.item-title {
		display: flex;
		justify-content: space-between;
		margin-bottom: .16rem;
	}

	.hot-content-item {
		margin-left: .2rem;
		box-sizing: border-box;
		padding-top: .17rem;
		padding-left: .16rem;
		padding-right: .16rem;
		width: 3.88rem;
		height: 2.2rem;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
	}

	.hot-content {
		display: flex;
		font-family: PingFang SC;
		margin-bottom: .18rem;
	}

	.hot-title img {
		margin-bottom: .43rem;
		width: 1.6rem;
	}

	.hot {
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		padding-top: .9rem;
		width: 19.2rem;
		height: 5.56rem;
	}

	.banner-main-item-content {
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
	}

	.banner-main-item-title {
		margin-bottom: .15rem;
		font-size: .20rem;
		font-weight: 600;
		color: #333333;
	}

	.banner-main-item img {
		margin-bottom: .12rem;
		width: .98rem;
		height: .98rem;
	}

	.banner-main-item {
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 4rem;
	}

	.banner-main {
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.banner-note {
		margin-bottom: .76rem;
		font-size: .2rem;
		font-weight: 400;
		color: #999999;
	}

	.banner-title {
		margin-bottom: .1rem;
		font-size: .42rem;
		font-weight: bold;
		color: #3C3C3C;
	}

	.banner {
		box-sizing: border-box;
		padding-top: .83rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 19.2rem;
		background: #F2F4F7;
		font-family: PingFang SC;
	}

	.main-button-item2 {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: .77rem;
		width: 2.17rem;
		height: .56rem;
		background: #DBDBDB;
		border-radius: 30px;
		font-size: .2rem;
		cursor: pointer;
	}

	.main-button-item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.17rem;
		height: .56rem;
		background: #08C8BD;
		border-radius: 30px;
		font-size: .2rem;
		cursor: pointer;
	}

	.main-button {
		display: flex;
	}

	.main-souce {
		margin-bottom: .6rem;
		font-size: .28rem;
		font-weight: 400;

	}

	.main-money {
		margin-bottom: .16rem;
		font-size: .48rem;
		font-weight: bold;
	}

	.content-main {
		padding-top: 3.2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-family: PingFang SC;
		color: #FFFFFF;
	}

	.bg {
		width: 19.2rem;
		height: 8.9rem;
		position: relative;
	}

	.bg-img {
		position: absolute;
		width: 19.2rem;
		height: 8.9rem;
		z-index: 2;
	}

	.bg-m {
		position: absolute;
		width: 19.2rem;
		height: 8.9rem;
		background: #000000;
		opacity: 0.4;
		z-index: 9;
	}

	.bg-content {
		position: absolute;
		width: 19.2rem;
		height: 8.9rem;
		z-index: 999;
	}

	.detail-desc {
		display: flex;
		flex-direction: column;
		margin-left: 0.8rem;
		width: 7.3rem;
	}

	.detail-desc-item {
		display: flex;
		align-items: center;
		margin-bottom: 110px;
		cursor: pointer;
	}

	.detail-desc-item:nth-last-child(1) {
		margin-bottom: 0;
	}

	.detail-desc-item img {
		width: 0.72rem;
		height: 0.72rem;
		margin-right: 0.16rem;
	}

	.detail-desc-item p {
		font-size: 24px;
		font-family: .PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #666666;
		margin-bottom: 14px;
	}

	.detail-desc-item dd {
		font-size: 18px;
		font-family: .PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	
	.detail-desc-item2 img {
		width: 1.04rem;
		height: 1.04rem;
	}
	
	.detail-desc-item2 p {
		font-size: 36px;
		font-family: .PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #666666;
		margin-bottom: 14px;
	}
	
	.detail-desc-item2 dd {
		font-size: 24px;
		font-family: .PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.sw-img2 {
		width: 4.48rem;
		height: 6.92rem;
		object-fit: contain;
	}

	.shengengSwiper {
		display: flex;
		align-items: center;
	}

	.shengengSwiper .swiper-container {
		margin-left: 0.72rem !important;
		margin-right: 0.72rem !important;
	}

	.shengengSwiper-button {
		width: 0.56rem;
		height: 0.56rem;
	}

	.swiper-button-prev,
	.swiper-button-next {
		position: relative !important;
	}
	
	.blue p, .blue dd {
		color: #368FEA !important;
	}
	
	.green p, .green dd {
		color: #00C69E !important;
	}
	
	.orange p, .orange dd {
		color: #F04608 !important;
	}
</style>
